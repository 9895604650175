<template>
  <div
    ref="dropdown"
    tabindex="0"
    data-luko-tracking="FooterLanguageSelector"
    class="inline-flex cursor-pointer items-center gap-[10px] py-[32px]"
    @click="onToggle()"
    @blur="onBlur"
  >
    <PImage lazy :image-object="data.footer_language_icon" sizes="xs:100px" class="h-[22px] w-[22px]" />
    <span class="text-white text-opacity-70">{{ data.footer_language_label }}</span>
    <span>{{ currentLocale.name }}</span>
    <ChevronDownIcon class="h-[14px] w-[14px] transition-transform path-white" :class="{ 'rotate-180': showDropdown }" />

    <transition name="selector">
      <ul v-if="showDropdown" :class="$style.dropdown" class="absolute -bottom-[34px] left-0 block w-[200px] rounded-[8px] bg-white">
        <li v-for="locale in availableLocales" :key="locale.code">
          <PLink
            :link-object="switchLangFormattedUrl(locale.prismicIso)"
            class="inline-block !w-full rounded-[8px] p-[16px] px-[14px] text-black hover:bg-gray-75"
          >
            {{ locale.name }}
          </PLink>
        </li>
      </ul>
    </transition>
  </div>
</template>
<script>
import ChevronDownIcon from 'LkIcons/ChevronDown.vue'
import { mapState } from 'vuex'

export default {
  components: {
    ChevronDownIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDropdown: false,
    }
  },
  computed: {
    ...mapState(['alternateLanguages']),
    currentLocale() {
      return this.$i18n.locales.find((locale) => locale.code === this.$i18n.locale)
    },
    availableLocales() {
      return this.$i18n.locales.filter((locale) => locale.code !== this.$i18n.locale)
    },
  },
  methods: {
    onToggle(v) {
      this.showDropdown = v !== undefined ? v : !this.showDropdown
      if (this.showDropdown) {
        this.$refs.dropdown.focus()
      }
    },
    onBlur() {
      this.showDropdown = false
    },
    switchLangFormattedUrl(lang) {
      if (lang === this.$i18n.localeProperties.prismicIso) {
        return {
          link_type: 'Web',
          url: this.$route.path,
          lang,
        }
      }
      lang = lang.toLowerCase()
      const alterPage = this.alternateLanguages?.find((item) => item.lang === lang)
      return {
        link_type: 'Document',
        type: alterPage?.type || 'home_page',
        lang,
        uid: alterPage?.uid,
      }
    },
  },
}
</script>
<style lang="scss" module>
.dropdown {
  &::before {
    position: absolute;
    top: 8px;
    right: 40px;

    width: 24px;
    height: 8px;

    background-image: url('~assets/icons/header/menu-pointer.svg');
    transform: translate(-50%, -16px);

    transition-duration: 0.2s;
    transition-property: opacity, transform;

    content: '';
  }
}
</style>
