<template>
  <div class="bg-white p-0 px-[24px] shadow-lg sm:px-[16px]">
    <Wrapper class="flex h-[64px] w-full items-center sm:h-[56px]">
      <BuggerMenu v-if="hasBuggerMenu" theme="light" @click="isMobileMenuOpen = true" />

      <LukoLogo theme="light" />

      <MobileNavigation
        v-if="data.body.length || data.desktop_links.length"
        :data="data"
        :is-mobile-nav-open="isMobileMenuOpen"
        @toggle-menu="isMobileMenuOpen = $event"
      />

      <div class="flex w-full justify-between">
        <DesktopNavigation :data="data" theme="light" />
        <RightNav :data="data" theme="light" />
      </div>
    </Wrapper>
  </div>
</template>

<script>
import { getOnboardingLink } from '@/mixins/getOnboardingLink'
import { headerMixins } from '@/mixins/header'

import MobileNavigation from '../components/MobileNavigation'
import LukoLogo from '../components/LukoLogo'
import BuggerMenu from '../components/BuggerMenu'
import DesktopNavigation from '../components/DesktopNavigation'
import RightNav from '../components/RightNav'

export default {
  name: 'Header2021Q4',

  components: {
    MobileNavigation,
    DesktopNavigation,
    RightNav,
    LukoLogo,
    BuggerMenu,
  },

  mixins: [getOnboardingLink, headerMixins],

  props: {
    data: {
      type: Object,
      required: true,
    },
    hasBuggerMenu: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
