<template>
  <section :data-luko-tracking="`Slice-${slice.slice_type}@${slice.variation}`">
    <slot />
  </section>
</template>

<script>
export default {
  props: {
    slice: {
      type: Object,
      required: true,
    },
  },
}
</script>
