<template>
  <div data-luko-tracking="MobileNav" :class="[$style.container]">
    <transition name="menu-fade-left">
      <div v-show="isMobileNavOpen" class="fixed top-0 left-0 z-50 h-screen w-[80%] max-w-[320px] bg-white transition-transform duration-300">
        <div class="flex justify-between p-[10px] px-[16px]">
          <button
            class="cursor-pointer rounded-[4px] border-none bg-none p-[4px]"
            data-luko-tracking="CloseBuggerMenu"
            @click="$emit('toggle-menu', false)"
          >
            <CrossIcon />
          </button>

          <PLink
            v-if="!accountDropdownMenu"
            :simple-url="accountButtonLink"
            class="rounded-[8px] border-none p-[8px] text-[14px] font-[500] leading-[20px] hover:bg-gray-50"
            data-luko-tracking="AccountCta"
          >
            {{ data.account_button }}
          </PLink>
        </div>
        <nav class="h-[calc(100vh-52px)] overflow-y-auto">
          <ul>
            <li v-for="(menu, menuIndex) in sortedMenuLists" :key="'menu_' + menuIndex">
              <DropDown :title="menu.primary.menu_list_title || menu.primary.menu_category" :links="menu.items" />
            </li>
            <li v-for="(desktopLink, linkIndex) in data.desktop_links" :key="'link_' + linkIndex">
              <PLink
                class="!w-full p-[16px] text-gray-700 no-underline transition-colors duration-300 hover:bg-gray-50 hover:text-black"
                :link-object="desktopLink.desktop_link_url"
                :data-luko-tracking="desktopLink.desktop_link_name"
              >
                {{ desktopLink.desktop_link_name }}
              </PLink>
            </li>
            <li v-if="accountDropdownMenu">
              <DropDown :title="data.account_button" :links="accountDropdownMenu.items" />
            </li>
          </ul>
        </nav>
      </div>
    </transition>

    <transition name="menu-backdrop-fade-in">
      <div v-if="isMobileNavOpen" class="fixed top-0 left-0 z-10 h-screen w-full bg-black opacity-40" @click="$emit('toggle-menu', false)"></div>
    </transition>
  </div>
</template>

<script>
import CrossIcon from 'LkIcons/Cross.vue'

import { getMyLukoLink } from '@/mixins/getMyLukoLink'

import DropDown from './components/DropDown'

export default {
  name: 'MobileNavigation',

  components: {
    DropDown,
    CrossIcon,
  },

  mixins: [getMyLukoLink],

  props: {
    data: {
      type: Object,
      required: true,
    },
    isMobileNavOpen: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    sortedMenuLists() {
      return this.data.body.filter((s) => s.slice_type === 'menu_list')
    },
    accountDropdownMenu() {
      return this.data.body.find((s) => s.slice_type === 'account_button_menu_list')
    },
    accountButtonLink() {
      return this.data.account_button_link?.url ? this.data.account_button_link.url : this.getMyLukoLink()
    },
  },

  methods: {
    closeMenus() {
      for (const menu of this.sortedMenuLists) {
        menu.primary.is_menu_open = false
      }
    },
  },
}
</script>

<style lang="scss" module>
.container {
  @media (min-width: 1024px) {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
.menu-fade-left-enter-active,
.menu-fade-left-leave-active {
  left: 0;

  transition: opacity 0.3s, left 0.3s;
}
.menu-fade-left-enter, .menu-fade-left-leave-to /* .fade-leave-active below version 2.1.8 */ {
  left: -8px;

  opacity: 0;
}
.menu-backdrop-fade-in-enter-active,
.menu-backdrop-fade-in-leave-active {
  transition: opacity 0.3s;
}
.menu-backdrop-fade-in-enter, .menu-backdrop-fade-in-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
