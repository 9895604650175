import Vue from 'vue'

import Wrapper from '@/components/Wrapper'
import PImage from '@/components/PImage'
import Title from '@/components/Title'
import RichText from '@/components/RichText'
import PLink from '@/components/PLink'
import SliceWrapper from '@/components/SliceWrapper'
import LkButton from '@/components/Lukomponents/LkButton'

Vue.component('Wrapper', Wrapper)
Vue.component('PImage', PImage)
Vue.component('LkButton', LkButton)
Vue.component('Title', Title)
Vue.component('RichText', RichText)
Vue.component('PLink', PLink)
Vue.component('SliceWrapper', SliceWrapper)
