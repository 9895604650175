export const state = () => ({
  id: 0,
})

export const mutations = {
  UPDATE(state) {
    state.id = state.id + 1
  },
}

export const getters = {
  getLukid(state) {
    return `lukid-${state.id}`
  },
}
