export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const UNSUPPORTED_FRENCH_DEPARTMENTS = ['20', '97', '98']

export const COOKIE = {
  LK_CMS: 'lk_cms',
}

export const COUNTRIES = {
  fr: 'France',
  es: 'Spain',
  de: 'Germany',
}

export const ONBOARDING_TYPE = {
  home: 'home',
  mri: 'mri',
  mortgage: 'mortgage',
  escooter: 'escooter',
  pet: 'pet',
  gli: 'gli',
}
