// How to use:
// => window.events.$emit('setVariation', payload = {key: "", value: ""})

import mitt from 'mitt'

import QuoteApi from '@/api/quote'

const { on, emit } = mitt()

export default ({ store }) => {
  window.events = {}
  window.events.$on = (...args) => on(...args)
  window.events.$emit = (...args) => emit(...args)

  window.events.$on('setVariation', (payload) => {
    console.debug('Set Variation: ', payload)
    store.commit('variations/UPDATE', payload)
  })

  window.events.$on('getQuote', async () => {
    if (process.env.IS_DEV !== 'true') return

    const mortgageProspect = {
      product: 'FR_MORTGAGE',
      package: 'france-mortgage-adaptive-package',
      mortgage: {
        main_asset: {
          purpose: 'secondary',
          bank_name: 'Banque Populaire',
        },
      },
      loan: {
        loan: [
          {
            initial_amount: 20000000, // cents
            initial_duration_in_months: 200,
            initial_start_date: '2021-01-01',
            deferral_in_months: 0,
            type: 'depreciable',
            rate_percentage: 1,
            deferral_type: 'partial',
            itt_deductible: 90,
          },
        ],
      },
      user: {
        subscriber: {
          birth_date: '1984-08-20',
          is_smoker: false,
          profession_category: 'Cadre / Assimilé Cadre',
          mortgage_quotity: 100,
        },
      },
    }
    try {
      const data = await QuoteApi.createQuote(mortgageProspect)
      console.log(data)
    } catch (error) {
      console.log(error)
    }
  })
}
