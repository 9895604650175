<template>
  <div class="relative" data-luko-tracking="ProfileDropdown">
    <div ref="profileRoot" class="inline-flex cursor-pointer items-center p-0" @click="onClickOnProfileButton" @blur="onBlur">
      <span class="flex h-[40px] w-[40px] items-center justify-center rounded-[50%] bg-gray-100 text-[14px] font-[500] uppercase text-gray-700">
        {{ userInitials }}
      </span>
      <ChevronDownIcon
        class="ml-[6px] h-[16px] w-[16px] shrink-0 transition-transform duration-300 path-white"
        :class="{
          ['rotate-180']: isDropdownShown,
          ['!path-black']: isPageScrolled || ['light', 'pink'].includes(theme),
        }"
      />
    </div>
    <transition name="fade-down">
      <div
        v-show="isDropdownShown"
        class="absolute top-[52px] -right-[60px] w-[300px] rounded-[8px] bg-white shadow-lg"
        :class="$style.profileDropdown"
      >
        <PLink
          class="!flex !w-full items-center gap-[16px] rounded-[8px] py-[14px] px-[30px] hover:bg-gray-50"
          :simple-url="getMyLukoLink()"
          target="_blank"
          rel="noopener"
        >
          <PersonIcon class="h-[24px] w-[24px] shrink-0 path-black" />
          <span>{{ profileTexts.account }}</span>
        </PLink>
        <PLink
          class="!flex !w-full items-center gap-[16px] rounded-[8px] py-[14px] px-[30px] hover:bg-gray-50"
          simple-url="https://faq.luko.eu/"
          target="_blank"
          rel="noopener"
        >
          <QuestionIcon class="h-[24px] w-[24px] shrink-0 path-black" />
          <span>{{ profileTexts.faq }}</span>
        </PLink>
        <hr class="border-t-[1px] border-solid border-gray-75" />
        <div class="!flex !w-full cursor-pointer items-center gap-[16px] rounded-[8px] py-[14px] px-[30px] hover:bg-gray-50" @click="logout">
          <LogoutIcon class="h-[24px] w-[24px] shrink-0 path-black" />
          <span>{{ profileTexts.logout }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import PersonIcon from 'LkIcons/Person.vue'
import QuestionIcon from 'LkIcons/Question.vue'
import LogoutIcon from 'LkIcons/Logout.vue'
import ChevronDownIcon from 'LkIcons/ChevronDown.vue'

import { getMyLukoLink } from '@/mixins/getMyLukoLink'
import { removeCookie } from '@/utils/cookies'
import { COOKIE } from '@/utils/constants'

export default {
  name: 'ProfileDropdown',

  components: {
    PersonIcon,
    QuestionIcon,
    LogoutIcon,
    ChevronDownIcon,
  },

  mixins: [getMyLukoLink],

  props: {
    username: {
      type: Object,
      default: null,
    },
    theme: {
      type: String,
      default: 'light',
    },
  },

  data() {
    return {
      isDropdownShown: false,
    }
  },

  computed: {
    firstname() {
      return this.username?.firstname
    },
    lastname() {
      return this.username?.lastname
    },
    userInitials() {
      if (!this.firstname || !this.lastname) {
        return null
      }
      const firstnameSplitted = this.firstname.split('-')
      if (firstnameSplitted.length === 2) {
        return firstnameSplitted[0][0] + firstnameSplitted[1][0]
      }
      const LASTNAME = this.lastname.toUpperCase()
      if (LASTNAME.startsWith('DE ') || LASTNAME.startsWith('DU ')) {
        return this.firstname[0] + LASTNAME[3]
      }
      if (LASTNAME.startsWith('DE LA ')) {
        return this.firstname[0] + LASTNAME[6]
      }
      return this.firstname[0] + LASTNAME[0]
    },

    profileTexts() {
      return {
        account: this.$i18n.locale === 'fr' ? 'Mon espace personnel' : 'My account',
        faq: this.$i18n.locale === 'fr' ? 'Questions fréquentes' : 'FAQ',
        logout: this.$i18n.locale === 'fr' ? 'Déconnexion' : 'Logout',
      }
    },
    isPageScrolled() {
      return this.$store.state.header.fixedHeader
    },
  },

  methods: {
    logout() {
      this.$store.commit('user/DELETE')
      removeCookie(COOKIE.LK_CMS)
      window.storage.remove('intercom_token')
      window.storage.remove('user_id')
    },

    onClickOnProfileButton() {
      this.isDropdownShown = !this.isDropdownShown
      if (this.isDropdownShown) {
        this.$refs.profileRoot.focus()
      }
    },

    onBlur() {
      this.isDropdownShown = false
    },
  },
}
</script>

<style lang="scss" module>
.profileDropdown {
  &::before {
    position: absolute;
    top: 8px;
    right: 12px;

    width: 24px;
    height: 8px;

    background-image: url('~assets/icons/header/menu-pointer.svg');
    transform: translate(-78px, -16px);

    transition-duration: 0.3s;
    transition-property: opacity, transform;

    content: '';
  }
}
</style>

<style lang="scss" scoped>
.fade-down-enter-active,
.fade-down-leave-active {
  transform: translateY(0);

  transition: opacity 0.3s, transform 0.3s;
}
.fade-down-enter, .fade-down-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(-8px);

  opacity: 0;
}
</style>
