import headerData from '@@/script/prismic/headerData.json'
import partnerHeaderData from '@@/script/prismic/partnerHeaderData.json'

export const state = () => ({
  headerData,
  partnerHeaderData,
  fixedHeader: false,
})

export const mutations = {
  SET_HEADER_DATA(state, data) {
    state.headerData = data
  },
  SET_HEADER_FIXED(state, bool) {
    state.fixedHeader = bool
  },
}
