export const excludedRoutes = [
  /\/seguros(\/)?$/, // products_hub
  /\/insurance(\/)?$/, // en products_hub
  /\/optin\/confirm(\/)?$/,
  /\/active-protection(\/)?$/,
  /\/compliance(\/)?$/,
  /\/perks(\/)?$/,
  /\/testimonials(\/)?$/,
  /\/en\/advice(\/)?$/,
  /\/en\/press(\/)?$/,
  /\/en\/partners(\/)?$/,
  /\/en\/contact(\/)?$/,
  /\/smart-certificate(\/)?$/,
  /\/price(\/)?$/,
  /\/pricing-policy(\/)?$/,
  /\/satisfaction(\/)?$/,
]

export default {
  advices_category_page: {
    'es-es': '/consejos',
    'en-es': '/en/advice',
  },
  advice_page: {
    'es-es': '/consejos/guia',
    'en-es': '/en/advice/guide',
  },
  contact_page: {
    'es-es': '/contacto',
    'en-es': '/en/contact',
  },
  error_page: {
    'es-es': '/not-found',
    'en-es': '/en/not-found',
  },
  faq_page: {
    'es-es': '/faq',
    'en-es': '/en/faq',
  },
  giveback_page: {
    'es-es': '/giveback',
    'en-es': '/en/giveback',
  },
  giveback_report_page: {
    'es-es': '/giveback',
    'en-es': '/en/giveback',
  },
  home_page: {
    'es-es': '/',
    'en-es': '/en',
  },
  home_services_page: {
    'es-es': '/servicios-hogar',
    'en-es': '/en/home-services',
  },
  advice_hub: {
    'es-es': '/consejos',
    'en-es': '/en/advice',
  },
  launch_page: {
    'es-es': '/inicio',
    'en-es': '/en/launch',
  },
  legal_page: {
    'es-es': '/',
    'en-es': '/en',
  },
  lukon_termination_page: {
    'es-es': '/cancelacion',
    'en-es': '/en/we-cancel-for-you',
  },
  partners: {
    'es-es': '/partners',
    'en-es': '/en/partners',
  },
  press_hub: {
    'es-es': '/prensa',
    'en-es': '/en/press',
  },
  press_release: {
    'es-es': '/prensa/release',
    'en-es': '/en/press/release',
  },
  press_article: {
    'es-es': '/prensa/article',
    'en-es': '/en/press/article',
  },
  product_page: {
    'es-es': '/seguros',
    'en-es': '/en/insurance',
  },
  refunds_page: {
    'es-es': '/reembolsos',
    'en-es': '/en/refunds',
  },
}
