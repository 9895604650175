<template>
  <div
    ref="dropdown"
    tabindex="0"
    class="relative flex cursor-pointer items-center gap-[8px]"
    data-luko-tracking="LanguageSelector"
    @click="onToggle()"
    @blur="onBlur"
  >
    <component :is="`Flag${$i18n.locale.toUpperCase()}`" />
    <ChevronDownIcon
      class="h-[16px] w-[16px] transition-transform duration-100 ease-linear path-black"
      :class="{ 'rotate-180': showDropdown, '!path-white': theme === 'dark' }"
    />
    <transition name="selector">
      <ul v-if="showDropdown" :class="$style.dropdown" class="absolute top-[45px] -left-[10px] w-[192px] rounded-[8px] bg-white p-[2px] shadow-lg">
        <li v-for="(locale, idx) in $i18n.locales" :key="idx" :data-luko-tracking="locale.code">
          <PLink
            :link-object="switchLangFormattedLinkObject(locale.prismicIso)"
            class="flex !w-full items-center gap-[18px] rounded-[8px] py-[14px] px-[20px] text-black no-underline hover:bg-gray-75"
            @click.stop="onToggle(false)"
          >
            <component :is="`Flag${locale.code.toUpperCase()}`" /> <span> {{ locale.name }}</span>
          </PLink>
        </li>
      </ul>
    </transition>
  </div>
</template>
<script>
import ChevronDownIcon from 'LkIcons/ChevronDown.vue'
import { mapState } from 'vuex'

import FlagDE from './de.svg?inline'
import FlagFR from './fr.svg?inline'
import FlagES from './es.svg?inline'
import FlagEN from './en.svg?inline'

export default {
  components: {
    FlagDE,
    FlagFR,
    FlagES,
    FlagEN,
    ChevronDownIcon,
  },

  props: {
    theme: {
      type: String,
      default: 'light',
    },
  },
  data() {
    return {
      showDropdown: false,
    }
  },
  computed: {
    ...mapState(['alternateLanguages']),
  },
  methods: {
    onToggle(v) {
      this.showDropdown = v !== undefined ? v : !this.showDropdown
      if (this.showDropdown) {
        this.$refs.dropdown.focus()
      }
    },
    onBlur() {
      this.showDropdown = false
    },
    switchLangFormattedLinkObject(lang) {
      if (lang === this.$i18n.localeProperties.prismicIso) {
        return {
          link_type: 'Web',
          url: this.$route.path,
          lang,
        }
      }
      lang = lang.toLowerCase()
      const alterPage = this.alternateLanguages?.find((item) => item.lang === lang)
      return {
        link_type: 'Document',
        type: alterPage?.type || 'home_page',
        lang,
        uid: alterPage?.uid,
      }
    },
  },
}
</script>
<style module lang="scss">
.dropdown {
  &::before {
    position: absolute;
    top: 8px;
    left: 26px;

    width: 24px;
    height: 8px;

    background-image: url('~assets/icons/header/menu-pointer.svg');
    transform: translate(-50%, -16px);

    transition-duration: 0.2s;
    transition-property: opacity, transform;

    content: '';
  }
}
</style>
