<template>
  <prismic-rich-text v-if="textParams.length && textParams[0] && textParams[0].type" :field="textParams" data-luko-tracking="RichText" />
</template>

<script>
export default {
  name: 'RichText',
  props: {
    textParams: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
