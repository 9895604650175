export const backgroundStyles = {
  computed: {
    backgroundStyles() {
      return (imageObject, sizes) => {
        if (!imageObject?.url) return {}

        const imgUrl = this.$img.getSizes(imageObject.url, {
          sizes: sizes || 'lg:110vw',
          modifiers: {
            quality: 80,
          },
        })
        return {
          backgroundImage: `url(${imgUrl.src})`,
        }
      }
    },
  },
}
