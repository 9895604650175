<template>
  <a
    v-if="computedLinkObject"
    :href="$prismic.asLink(computedLinkObject)"
    :data-luko-tracking="trackingId"
    class="inline-block"
    :target="computedTarget"
    :rel="rel"
    v-on="$listeners"
  >
    <slot />
  </a>
</template>

<script>
import { useUrl } from '@/composables/useUrl'
import { getFormattedLinkObject } from '@/mixins/getFormattedLinkObject'
const { urlToLinkObject } = useUrl()

export default {
  name: 'PLink',

  mixins: [getFormattedLinkObject],

  props: {
    linkObject: {
      type: Object,
      default: null,
    },
    simpleUrl: {
      type: String,
      default: '',
    },
    targetedSection: {
      type: String,
      default: null, // #section-id
    },
    target: {
      type: String,
      default: null,
    },
    rel: {
      type: String,
      default: undefined,
    },
    dataLukoTracking: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    computedTarget() {
      if (this.target) return this.target
      if (this.linkObject?.link_type === 'Media') return '_blank'
      if (this.linkObject) return this.linkObject.target
      return undefined
    },

    computedLinkObject() {
      if (this.linkObject) {
        return this.getFormattedLinkObject(this.linkObject, this.targetedSection)
      }
      if (this.simpleUrl) return this.getFormattedLinkObject(urlToLinkObject(this.simpleUrl), this.targetedSection)
      if (this.targetedSection) return this.getFormattedLinkObject(urlToLinkObject(process.env.URL + this.$route.fullPath), this.targetedSection)
      return null
    },
    computedLink() {
      return this.$prismic.asLink(this.computedLinkObject)
    },
    trackingId() {
      const ids = []
      if (this.dataLukoTracking) {
        ids.push(this.dataLukoTracking)
      }
      if (this.computedLink.includes('/onboard')) {
        ids.push('Onboarding')
      }
      return ids.join('-') || undefined
    },
  },
}
</script>
