export default ({ query }) => {
  let cnt = 0
  const showIntercom = query.show_intercom
  if (!showIntercom) return

  const intervalId = setInterval(() => {
    if (cnt === 27 || window.Intercom?.booted) {
      window.Intercom('show')
      return clearInterval(intervalId)
    }
    cnt++
  }, 200)
}
