<template>
  <prismic-rich-text v-if="titleParams.length" data-luko-tracking="Title" :field="titleParams" />
</template>

<script>
export default {
  name: 'Title',
  props: {
    titleParams: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
