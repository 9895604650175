<template>
  <div
    data-luko-tracking="DesktopNav"
    class="ml-[32px] flex items-center justify-between"
    :class="[$style.desktopNav, { [$style.isDark]: theme !== 'light' }]"
  >
    <nav class="xlg:hidden">
      <ul class="relative flex">
        <li v-for="(list, listIndex) in sortedMenuLists" :key="listIndex" :class="[$style.menuItem]">
          <span
            class="py-[16px] pr-[24px] pl-[8px]"
            :class="$style.menuItemSubListed"
            :data-luko-tracking="list[0].primary.menu_category || list[0].primary.menu_list_title"
            @mouseover="showImage"
            @click="showImage"
          >
            {{ list[0].primary.menu_category || list[0].primary.menu_list_title }}
          </span>
          <div :class="$style.subListContainer" :data-luko-tracking="list[0].primary.menu_category || list[0].primary.menu_list_title">
            <div
              v-for="(subList, subListIndex) in list"
              :key="subListIndex"
              class="min-w-[280px] basis-[50%]"
              :class="{
                [$style.secondarySubList]: subList.slice_type === 'secondary_menu_list',
              }"
            >
              <component
                :is="hasLink(subList.primary.menu_list_title_link) ? 'PLink' : 'p'"
                v-if="subList.primary.menu_list_title"
                class="block py-[8px] px-[24px] uppercase typo-caption-bold"
                :class="{ ['!text-gray-400']: !hasLink(subList.primary.menu_list_title_link) }"
                :link-object="linkObject(subList.primary.menu_list_title_link)"
                :data-luko-tracking="subList.primary.menu_list_title"
              >
                {{ subList.primary.menu_list_title }}
              </component>
              <div v-for="(link, linkIndex) in subList.items" :key="linkIndex">
                <PLink
                  v-if="subList.slice_type !== 'secondary_menu_list'"
                  :link-object="link.menu_item_url"
                  class="flex items-center py-[12px] px-[24px]"
                  :data-luko-tracking="link.menu_item_name"
                >
                  <figure class="mr-[16px] h-[24px] w-[24px]">
                    <PImage
                      :image-object="link.menu_item_icon"
                      lazy
                      sizes="xs:24px"
                      class="hidden h-full w-full object-contain"
                      :class="{ ['!block']: isShowImage }"
                    />
                  </figure>
                  <div>
                    <span class="mr-[8px]">
                      {{ link.menu_item_name }}
                    </span>
                    <span
                      v-if="link.is_new_item"
                      class="rounded-[2px] bg-gold-500 p-[4px] text-[10px] font-[500] uppercase leading-[10px] text-white"
                    >
                      {{ $t('new') }}
                    </span>
                  </div>
                </PLink>
                <PLink v-else :link-object="link.secondary_menu_item_link" target="_blank" :data-luko-tracking="link.secondary_menu_item_name">
                  {{ link.secondary_menu_item_name }}
                </PLink>
              </div>
            </div>
          </div>
        </li>
        <li
          v-for="(desktopLink, desktopLinkIndex) in data.desktop_links"
          :key="desktopLinkIndex"
          :class="[$style.menuItem]"
          class="py-[16px] px-[12px]"
        >
          <PLink :link-object="desktopLink.desktop_link_url" :data-luko-tracking="desktopLink.desktop_link_name">
            {{ desktopLink.desktop_link_name }}
          </PLink>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'DesktopNavigation',

  props: {
    data: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: 'light',
    },
  },

  data() {
    return {
      hasWaitlist: this.$store.state.hasWaitlist,
      isShowImage: false,
    }
  },

  computed: {
    sortedMenuLists() {
      return this.data.body
        .filter((s) => s.slice_type === 'menu_list' || s.slice_type === 'secondary_menu_list')
        .reduce((sortedMenus, currentMenu) => {
          sortedMenus[currentMenu.primary.menu_category] = sortedMenus[currentMenu.primary.menu_category] || []

          sortedMenus[currentMenu.primary.menu_category].push(currentMenu)

          return sortedMenus
        }, Object.create(null))
    },
  },

  methods: {
    hasLink(linkObject) {
      if (!linkObject) return false
      return linkObject.type || linkObject.url
    },

    linkObject(linkObject) {
      if (this.hasLink(linkObject)) return linkObject
      return undefined
    },
    showImage() {
      if (!this.isShowImage) this.isShowImage = true
    },
  },
}
</script>

<style lang="scss" module>
.desktopNav {
  a {
    color: $gray-1000;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;

    transition: opacity ease-in-out 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
}

.menuItem {
  position: relative;

  display: flex;
  align-items: center;

  color: $gray-1000;
  font-size: 16px;
  line-height: 24px;
  &:hover {
    .menuItemSubListed::before {
      transform: translate(-50%, -16px);
      opacity: 1;
    }
    .subListContainer {
      transform: translateY(0);
      opacity: 1;

      pointer-events: all;
    }
  }
}

.menuItemSubListed {
  position: relative;

  cursor: pointer;

  &::before {
    position: absolute;
    bottom: -30%;
    left: 50%;

    width: 24px;
    height: 8px;

    background-image: url('~assets/icons/header/menu-pointer.svg');
    transform: translate(-50%, -24px);
    opacity: 0;

    transition-duration: 0.3s;
    transition-property: opacity, transform;

    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;

    width: 6px;
    height: 6px;

    background-image: url('~assets/icons/header/chevron.svg');

    transform: translate(5px, -50%);

    content: '';
  }
}

.subListContainer {
  position: absolute;
  top: 100%;
  left: -32px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 16px;

  border-radius: 8px;
  overflow: hidden;

  background-color: white;
  box-shadow: 0px 24px 48px rgba(44, 35, 2, 0.24);
  transform: translateY(-8px);

  opacity: 0;

  transition-duration: 0.3s;
  transition-property: opacity, transform;

  pointer-events: none;
}

.secondarySubList {
  display: grid;
  flex-basis: 100%;
  flex-grow: 1;
  grid-column: 1/3;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 -16px -16px -16px;
  padding: 8px 40px;

  background-color: $gray-50;
}

.isDark {
  .menuItem > span,
  .menuItem > a {
    color: white !important;
  }
  .menuItem > a:hover,
  .menuItem > span:hover {
    opacity: 1 !important;
  }

  .menuItemSubListed:after {
    background-image: url('~assets/icons/header/chevron-light.svg');
  }
}
</style>
