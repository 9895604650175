<template>
  <component :is="tag" class="m-0 mx-auto max-w-[1280px]">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'Wrapper',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
}
</script>
