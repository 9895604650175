export const getMyLukoLink = {
  methods: {
    getMyLukoLink() {
      const lang = this.$i18n.locale
      return process.env.IS_DEV === 'true'
        ? `https://devnoscope.luko.eu/my-account/sign-in?lang=${lang}`
        : `https://www.luko.eu/my-account/sign-in?lang=${lang}`
    },
  },
}
