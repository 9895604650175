export const excludedRoutes = [
  /\/active-protection(\/)?$/,
  /\/compliance(\/)?$/,
  /\/home-services(\/)?$/,
  /\/versicherung(\/)?$/, // product hub
  /\/insurance(\/)?$/, // product hub
  /\/lukon-termination(\/)?$/,
  /\/press(\/)?$/,
  /\/satisfaction(\/)?$/,
  /\/smart-certificate(\/)?$/,
  /\/price(\/)?$/,
  /\/pricing-policy(\/)?$/,
]

export default {
  advices_category_page: {
    'de-de': '/service',
    'en-de': '/en/advice',
  },
  advice_page: {
    'de-de': '/service/tipps',
    'en-de': '/en/advice/guide',
  },
  contact_page: {
    'de-de': '/kontakt',
    'en-de': '/en/contact',
  },
  error_page: {
    'de-de': '/not-found',
    'en-de': '/en/not-found',
  },
  faq_page: {
    'de-de': '/faq',
    'en-de': '/en/faq',
  },
  giveback_page: {
    'de-de': '/giveback',
    'en-de': '/en/giveback',
  },
  giveback_report_page: {
    'de-de': '/giveback',
    'en-de': '/en/giveback',
  },
  home_page: {
    'de-de': '/',
    'en-de': '/en',
  },
  advice_hub: {
    'de-de': '/service',
    'en-de': '/en/advice',
  },
  legal_page: {
    'de-de': '/',
    'en-de': '/en',
  },
  launch_page: {
    'de-de': '/page',
    'en-de': '/en/page',
  },
  product_page: {
    'de-de': '/versicherung',
    'en-de': '/en/insurance',
  },
  perks_page: {
    'de-de': '/perks',
    'en-de': '/en/perks',
  },
  refunds_page: {
    'de-de': '/refunds',
    'en-de': '/en/refunds',
  },
  testimonials_page: {
    'de-de': '/kundenbewertungen',
    'en-de': '/en/testimonials',
  },
  email_confirmation_page: {
    'de-de': '/optin/confirm',
    'en-de': '/en/optin/confirm',
  },
  partners: {
    'de-de': '/partners',
    'en-de': '/en/partners',
  },
  partner_page: {
    'de-de': '/partners',
    'en-de': '/en/partners',
  },
}
