import axios from 'axios'

export default async ({ query, store }) => {
  const couponQuery = query.pc
  const couponLocal = window.sessionStorage.getItem('coupon')
  const coupon = couponQuery || couponLocal
  if (coupon) {
    try {
      const { data } = await axios.get(`${process.env.PARTNER_API}/v1/coupons/${coupon}`, {
        headers: { Authorization: `Bearer ${process.env.PARTNER_KEY}` },
      })
      window.LukoTracking.trackEvent({
        id: 'Coupon Fetched',
        properties: {
          coupon,
        },
      })

      if (data.code) {
        window.sessionStorage.setItem('coupon', coupon)
        store.commit('promoBanner/SET_TOP_BANNER_DATA', {
          amount: data.amount,
          content: data.i18n_banner_message,
          type: data.redeem_type,
          promo_code: coupon,
          code: data.code,
        })
      }
    } catch (err) {
      console.error(err)
    }
  }
}
