export const useUrl = () => {
  return {
    getHostByCountry: (country) => {
      const hostMap = {
        fr: 'fr.luko.eu',
        es: 'es.luko.eu',
        de: 'de.luko.eu',
      }
      return hostMap[country]
    },
    forwardUrl: (url) => {
      if (!process.client) return url
      if (!url.includes('/onboard') && !url.includes('/onboarding')) return url

      const searchParams = Array.from(new URLSearchParams(window.location.search).entries())
      const coupon = window.sessionStorage.getItem('coupon')
      if (coupon) {
        searchParams.push(['pc', coupon])
      }

      // attatch search params to existing links
      const Url = new URL(url)

      for (const e of searchParams) {
        // skip if link already has the query param
        if (!Url.searchParams.get(e[0])) {
          Url.searchParams.set(e[0], e[1])
        }
      }

      return Url.toString()
    },
    urlToLinkObject: (url) => {
      if (typeof url !== 'string') throw new Error(`urlToLinkObject: ${url} is not string.`)

      return {
        url,
        link_type: 'Web',
      }
    },
    appendQuery: (url, queryObj = {}) => {
      const urlObj = new URL(url)
      Object.entries(queryObj).forEach(([k, v]) => {
        if (!v) return
        if (urlObj.searchParams.has(k)) urlObj.searchParams.delete(k)
        urlObj.searchParams.append(k, v)
      })
      return urlObj.toString()
    },
  }
}
