<template>
  <footer class="bg-bluko-500" data-luko-tracking="Footer">
    <Wrapper class="p-0 px-[20px] pb-[24px] sm:pb-[32px]">
      <section class="relative border-b-[1px] border-solid border-[#ffffff1a] p-0 text-white">
        <LanguageSelector :data="footerData" />
      </section>

      <section class="flex gap-[5%] p-0 py-[32px] sm:py-[48px] xs:block">
        <div class="w-[260px]">
          <LukoInfo :data="footerData" />

          <div class="mt-[32px] flex items-start opacity-50">
            <PImage
              v-if="footerData.footer_bcorp_logo && footerData.footer_bcorp_logo.url"
              :image-object="footerData.footer_bcorp_logo"
              lazy
              class="mt-[4px] h-[80px] w-[48px] flex-shrink-0 object-contain"
            />
            <p class="ml-[16px] text-[14px] leading-[20px] text-white">{{ footerData.footer_bcorp_text }}</p>
          </div>
        </div>

        <div class="flex flex-wrap sm:block">
          <div v-for="(list, listIndex) in sortedFooterLists" :key="'slice-' + listIndex" class="mt-[56px] ml-[24px] w-[260px] sm:ml-0">
            <div v-for="(item, itemIndex) in list" :key="'item-list' + itemIndex" :data-luko-tracking="item.primary.footer_link_list_title">
              <p
                v-if="item.primary.footer_link_list_title"
                :key="'item-title' + itemIndex"
                class="mb-[8px] font-[500] leading-[24px] text-white opacity-50"
              >
                {{ item.primary.footer_link_list_title }}
              </p>
              <ul :class="{ [$style.imageList]: !item.primary.footer_link_list_title }">
                <li v-for="(link, linkIndex) in item.items" :key="'list-' + linkIndex">
                  <template v-if="link.footer_link_list_item_text && link.footer_link_list_item_url">
                    <span
                      v-if="link.footer_link_list_item_url.url && link.footer_link_list_item_url.url.includes('Didomi')"
                      :class="$style.link"
                      :data-luko-tracking="link.footer_link_list_item_text"
                      @click="openDidomi()"
                    >
                      {{ link.footer_link_list_item_text }}
                    </span>
                    <PLink
                      v-else
                      :link-object="link.footer_link_list_item_url"
                      :class="$style.link"
                      :data-luko-tracking="link.footer_link_list_item_text"
                    >
                      {{ link.footer_link_list_item_text }}
                    </PLink>
                  </template>
                  <template v-else>
                    <PLink :link-object="link.footer_image_list_item_url">
                      <PImage v-if="link.footer_image_list_item_image.url" :image-object="link.footer_image_list_item_image" lazy />
                    </PLink>
                  </template>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <p class="border-t-[1px] border-solid border-[#ffffff1a] pt-[32px] text-[14px] leading-[20px] text-white opacity-50">
        {{ footerData.footer_legal_text }}
      </p>
    </Wrapper>
  </footer>
</template>

<script>
import footerData from '@@/script/prismic/footerData.json'

import LanguageSelector from './components/LanguageSelector'
import LukoInfo from './components/LukoInfo'

export default {
  components: { LanguageSelector, LukoInfo },

  computed: {
    footerData() {
      return footerData[this.$i18n.localeProperties.prismicIso.toLowerCase()]
    },
    sortedFooterLists() {
      const result = this.footerData.body.reduce(function (sortedLists, currentList) {
        sortedLists[currentList.primary.footer_list_category] = sortedLists[currentList.primary.footer_list_category] || []

        sortedLists[currentList.primary.footer_list_category].push(currentList)

        return sortedLists
      }, Object.create(null))

      return result
    },
  },
  methods: {
    openDidomi() {
      window.Didomi.notice.show()
    },
  },
}
</script>

<style lang="scss" module>
.link {
  display: block;
  margin-top: 8px;

  color: white;
  line-height: 24px;
  text-decoration: none;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.imageList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 24px;
}
</style>
