<template>
  <main>
    <section v-observe-visibility="isVisible" class="-mt-[64px] bg-orange-25 px-[24px]">
      <Wrapper
        class="
          flex
          min-h-[720px]
          flex-col
          items-center
          bg-auto bg-right-bottom bg-no-repeat
          pt-[192px]
          text-center
          sm:min-h-[630px] sm:bg-[length:70vw_60vw] sm:pt-[132px] sm:text-left
        "
        :style="background"
      >
        <div class="md:mx-auto">
          <Title :title-params="errorData.title" class="mx-auto mb-[16px] max-w-[680px] typo-mega-title md:typo-extra-large-title" />
          <RichText :text-params="errorData.description" class="mb-[48px] !text-gray-700 typo-headline md:typo-body" />
          <LkButton v-if="errorData.cta_title" :link-object="backToHomeLinkObject">
            {{ errorData.cta_title }}
          </LkButton>
        </div>
      </Wrapper>
    </section>
  </main>
</template>

<script>
import errorData from '@@/script/prismic/errorData.json'

import { backgroundStyles } from '@/mixins/backgroundStyles'

export default {
  name: 'ErrorPage',

  mixins: [backgroundStyles],

  computed: {
    errorData() {
      return errorData[this.$i18n.localeProperties.prismicIso.toLowerCase()]
    },
    background() {
      return this.backgroundStyles(this.errorData?.image, 'sm:550px')
    },
    backToHomeLinkObject() {
      return {
        link_type: 'Document',
        type: 'home_page',
        lang: this.$i18n.localeProperties.prismicIso.toLowerCase(),
      }
    },
  },

  mounted() {
    if (window.analytics) {
      window.analytics.track('Page not found', {
        referrer: document.referrer,
        location: document.location.href || document.URL,
        prospect: window.storage.get('about_utm'),
      })
    } else {
      console.debug('Analytics not loaded')
    }
    window.LukoTracking.trackEvent({
      id: 'Page not found',
      properties: {
        error: { message: `Page not found` },
      },
    })
  },
  methods: {
    isVisible(visible) {
      this.$store.commit('header/SET_HEADER_FIXED', !visible)
    },
  },
}
</script>
