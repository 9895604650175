<template>
  <div
    :class="{ ['text-black']: isOpen, ['bg-gray-50']: isOpen }"
    class="text-gray-700 transition-colors duration-300 hover:text-black"
    :data-luko-tracking="`Dropdown-${title}`"
  >
    <p class="flex cursor-pointer items-center gap-[8px] p-[16px] hover:bg-gray-50" @click="toggleMenuItem()">
      {{ title }}
      <ChevronIcon class="h-[6px] w-[6px] shrink-0 transition-transform duration-200" :class="{ ['rotate-180']: isOpen }" />
    </p>
    <ul v-if="isOpen">
      <li v-for="(link, linkIndex) in links" :key="linkIndex">
        <PLink
          :link-object="link.menu_item_url || link.link"
          class="!flex !w-full items-center gap-[16px] py-[12px] px-[16px] leading-[20px] text-black hover:bg-gray-100"
          :data-luko-tracking="link.menu_item_name || link.link_text"
        >
          <PImage
            v-if="(link.menu_item_icon && link.menu_item_icon.url) || (link.icon && link.icon.url)"
            :image-object="link.menu_item_icon || link.icon"
            lazy
            sizes="xs:24px"
            class="h-[24px] w-[24px] object-contain"
          />
          <div>
            <span class="leading-[24px]">
              {{ link.menu_item_name || link.link_text }}
            </span>
            <span v-if="link.is_new_item" class="ml-[4px] rounded-[2px] bg-gold-1000 p-[2px] text-[10px] font-[500] uppercase text-white">
              {{ $t('new') }}
            </span>
          </div>
        </PLink>
      </li>
    </ul>
  </div>
</template>

<script>
import ChevronIcon from '@/assets/icons/header/chevron.svg?inline'

export default {
  name: 'MobileNavigationDropDown',
  components: {
    ChevronIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    links: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },

  methods: {
    toggleMenuItem() {
      if (this.isOpen) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    },
  },
}
</script>
