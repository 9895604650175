export const state = () => ({
  values: {
    HomePageTitle: {
      de: {
        de: null,
        en: null,
      },
    },
    HomePageSubtitle: {
      de: {
        de: null,
        en: null,
      },
    },
  },
})

export const mutations = {
  UPDATE(state, payload) {
    state.values[payload.key] = payload.value
  },
  DELETE(state, key) {
    delete state.values[key]
  },
}
