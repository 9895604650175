<template>
  <div class="flex items-center gap-[10px]">
    <LanguageSelector v-if="$i18n.defaultLocale === 'de'" :theme="theme" />

    <client-only>
      <ProfileDropdown v-if="hasAccount" :theme="theme" :data-is-page-scrolled="isPageScrolled" :username="username" class="xs:hidden" />
    </client-only>

    <template v-if="!accountMenu">
      <PLink
        :simple-url="accountButtonLink"
        :class="[{ ['text-white']: isDark, ['hidden']: hasAccount }, $style.myLukoLink]"
        class="py-[14px] px-[4px] transition-colors duration-300 hover:opacity-70"
      >
        {{ data.account_button }}
      </PLink>
    </template>
    <template v-else>
      <div :class="{ [$style.accountButton]: true, ['text-white']: isDark, ['hidden']: hasAccount }" class="relative xlg:hidden">
        <span class="cursor-pointer py-[14px] px-[4px]" data-luko-tracking="AccountMenu" @mouseover="showImage" @click="showImage">
          {{ data.account_button }}
        </span>
        <div
          data-luko-tracking="AccountMenu"
          class="
            absolute
            top-[150%]
            left-0
            flex
            w-[256px]
            -translate-y-[8px]
            items-center
            rounded-[8px]
            bg-white
            text-black
            opacity-0
            shadow-lg
            transition-all
            duration-300
          "
          :class="$style.subListContainer"
        >
          <ul class="my-[8px] w-full">
            <li v-for="menu in accountMenu.items" :key="menu.link_text">
              <PLink
                class="flex w-full items-center object-contain py-[8px] px-[24px] hover:opacity-70"
                :link-object="menu.link"
                :data-luko-tracking="menu.link_text"
              >
                <figure class="mr-[16px] h-[22px] w-[22px]">
                  <PImage :image-object="menu.icon" lazy class="hidden h-full w-full object-contain" :class="{ ['!block']: isShowImage }" />
                </figure>
                <span>{{ menu.link_text }}</span>
              </PLink>
            </li>
          </ul>
        </div>
      </div>
    </template>

    <LkButton
      v-if="!hasWaitlist && data.cover_button"
      :link-object="coverButtonLinkObject"
      :class="{ [$style.isMobileScrolled]: isPageScrolled }"
      class="sm:none"
      is-small
      :is-outlined="theme === 'pink'"
      :is-white="isDark"
      data-luko-tracking="CoverCta"
    >
      {{ $store.state.navCta.text || ctaText }}
    </LkButton>
    <PLink v-else-if="hasWaitlist && waitlistCta" :class="$style.waitlistCta" :link-object="waitlistCta.primary.cta_link">
      {{ $t('waitlistCta') }}
    </PLink>
  </div>
</template>

<script>
import { getOnboardingLink } from '@/mixins/getOnboardingLink'
import { getMyLukoLink } from '@/mixins/getMyLukoLink'
import { useUrl } from '@/composables/useUrl'

import ProfileDropdown from '../ProfileDropdown'
import LanguageSelector from '../LanguageSelector'

const { urlToLinkObject } = useUrl()

export default {
  name: 'DesktopNavigation',

  components: {
    ProfileDropdown,
    LanguageSelector,
  },

  mixins: [getOnboardingLink, getMyLukoLink],

  props: {
    data: {
      type: Object,
      required: true,
    },
    theme: {
      type: String,
      default: 'light',
    },
  },

  data() {
    return {
      hasWaitlist: this.$store.state.hasWaitlist,
      isShowImage: false,
    }
  },
  computed: {
    isDark() {
      return !['light', 'pink'].includes(this.theme)
    },
    isPageScrolled() {
      return this.$store.state.header.fixedHeader
    },
    username() {
      const user = this.$store.state.user

      if (user.lastname && user.firstname) {
        return { lastname: user.lastname, firstname: user.firstname }
      }
      return null
    },
    contractPk() {
      return this.$store.state.user.pk
    },

    ctaText() {
      return this.contractPk ? this.$t('header.contractCta') : this.data.cover_button
    },

    accountMenu() {
      return this.data.body.find((s) => s.slice_type === 'account_button_menu_list')
    },

    waitlistCta() {
      return this.data.body.find((s) => s.slice_type === 'waitlist_cta')
    },

    accountButtonLink() {
      return this.data.account_button_link?.url ? this.data.account_button_link.url : this.getMyLukoLink()
    },

    coverButtonLinkObject() {
      return this.data.cover_button_link?.url
        ? this.data.cover_button_link
        : urlToLinkObject(this.$store.state.navCta.url || this.getOnboardingLink(null, { queryParams: [['$.key', this.contractPk]] }))
    },
    hasAccount() {
      return !!this.username && this.$store.state.page.settings.navigation_type !== 'partner_navigation'
    },
  },
  methods: {
    showImage() {
      if (!this.isShowImage) this.isShowImage = true
    },
  },
}
</script>

<style lang="scss" module>
@keyframes tada {
  from {
    transform: translateY(-30px);
  }
  to {
    transform: translateY(0);
  }
}

.isMobileScrolled {
  @include below(small) {
    display: flex !important;

    transform: translateY(-60px);

    animation-name: tada;
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0, 1.68, 0.56, 1.24);
    animation-delay: 0.8s;
    animation-fill-mode: forwards;
  }
}

.accountButton {
  &:hover > .subListContainer {
    transform: translateY(0);
    opacity: 1;

    pointer-events: all;
  }
}

.subListContainer {
  pointer-events: none;

  &::before {
    position: absolute;
    top: 8px;
    left: 15%;

    width: 24px;
    height: 8px;

    background-image: url('~assets/icons/header/menu-pointer.svg');
    transform: translate(-50%, -16px);

    transition-duration: 0.3s;
    transition-property: opacity, transform;

    content: '';
  }
}

.waitlistCta {
  display: block;
  margin-left: 32px;
  padding: 8px 16px;
  border: solid 2px $gray-100;
  border-radius: 8px;

  color: $bluko-500 !important;

  &:hover {
    color: $bluko-400 !important;
  }
}

.myLukoLink {
  @media screen and (max-width: 555px) {
    display: none !important;
  }
}
</style>
