<template>
  <div id="mainRoot" data-luko-tracking="App-website">
    <transition name="infoBox">
      <InfoBox v-if="notification.text" :type="notification.type" class="fixed top-0 z-50" is-dismissible is-small @dismiss="onDismiss">
        {{ notification.text }}
      </InfoBox>
    </transition>

    <HeaderModule v-if="!$store.state.page.settings.removeHeader" />
    <nuxt />
    <FooterModule v-if="!$store.state.page.settings.removeFooter" />
  </div>
</template>

<script>
import speedkitHydrate from 'nuxt-speedkit/hydrate'
import footerData from '@@/script/prismic/footerData.json'

import FooterModule from '@/modules/Footer'
import HeaderModule from '@/modules/Header'

export default {
  name: 'DefaultLayout',

  head() {
    const i18nSeo = this.$nuxtI18nSeo()
    const canonical = {
      rel: 'canonical',
      href: `${process.env.URL}${this.$route.path}/`.replace(/\/\/$/, '/'),
    }

    return {
      htmlAttrs: {
        ...i18nSeo.htmlAttrs,
      },
      meta: [...i18nSeo.meta],
      link: [canonical],
    }
  },

  components: {
    InfoBox: speedkitHydrate(() => import('@/components/InfoBox')),
    HeaderModule,
    FooterModule,
  },

  data() {
    const lang = this.$i18n.localeProperties.prismicIso.toLowerCase()
    return {
      footerData: footerData[lang],
    }
  },

  computed: {
    notification() {
      return this.$store.state.notification
    },
  },

  methods: {
    onDismiss() {
      return this.$store.commit('notification/SET_NOTIFICATION', { text: '' })
    },
  },
}
</script>
