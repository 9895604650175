import { getCookie, removeCookie } from '@/utils/cookies'

const removeParameterFromUrl = (url, parameter) =>
  url.replace(new RegExp('[?&]' + parameter + '=[^&#]*(#.*)?$'), '$1').replace(new RegExp('([?&])' + parameter + '=[^&]*&'), '$1')

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const parseQuery = (queryString) => {
  const query = {}
  const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&')
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

const runAboutUtm = () => {
  if (!window.storage.get('about_utm')) {
    if (window.sessionStorage.getItem('about_utm')) {
      window.storage.set('about_utm', JSON.parse(window.sessionStorage.getItem('about_utm')))
    } else {
      window.storage.set('about_utm', {
        first_utm_source: null,
        first_utm_medium: null,
        first_utm_campaign: null,
        first_utm_content: null,
        first_utm_keyword: null,
        first_utm_term: null,
        first_url: null,
        current_utm_source: null,
        current_utm_medium: null,
        current_utm_campaign: null,
        current_utm_content: null,
        current_utm_keyword: null,
        current_utm_term: null,
        current_url: null,
        partner_user_id: null,
        uuid: uuidv4(),
        referrer: null,
      })
    }
  }
  const about_utm = window.storage.get('about_utm')

  const {
    utm_source = '',
    utm_medium = '',
    utm_campaign = '',
    utm_content = '',
    utm_keyword = '',
    utm_term = '',
    partner_user_id = '',
  } = parseQuery(location.search)

  const url = removeParameterFromUrl(window.location.href || document.documentURI, 'token')
  const referrer = document.referrer

  if (!window.sessionStorage.getItem('isCurrentSession')) {
    window.sessionStorage.setItem('isCurrentSession', true)
    about_utm.current_utm_source = utm_source
    about_utm.current_utm_medium = utm_medium
    about_utm.current_utm_campaign = utm_campaign
    about_utm.current_utm_content = utm_content
    about_utm.current_utm_keyword = utm_keyword
    about_utm.current_utm_term = utm_term
    about_utm.current_url = url
    about_utm.partner_user_id = partner_user_id
    about_utm.referrer = referrer
    if (
      about_utm.first_utm_source === null ||
      about_utm.first_utm_medium === null ||
      about_utm.first_utm_campaign === null ||
      about_utm.first_utm_content === null ||
      about_utm.first_utm_keyword === null ||
      about_utm.first_utm_term === null ||
      about_utm.first_url === null ||
      about_utm.referrer === null
    ) {
      about_utm.first_utm_source = utm_source
      about_utm.first_utm_medium = utm_medium
      about_utm.first_utm_campaign = utm_campaign
      about_utm.first_utm_content = utm_content
      about_utm.first_utm_keyword = utm_keyword
      about_utm.first_utm_term = utm_term
      about_utm.first_url = url
      about_utm.referrer = referrer
    }
    window.sessionStorage.setItem('isCurrentSession', true)
    window.storage.set('about_utm', about_utm)
  }
  window.sessionStorage.setItem('about_utm', JSON.stringify(about_utm))
}
runAboutUtm()

const sendPageDatalayer = () => {
  let about_utm = ''
  try {
    about_utm = JSON.parse(getCookie('about_utm'))
  } catch (error) {
    removeCookie('about_utm')
  }

  if (window.analytics) {
    analytics.page({
      path: document.location.pathname,
      referrer: document.referrer,
      search: document.location.search,
      title: document.title,
      url: document.location.href,
    })

    analytics.track('Page Datalayer', {
      name: window.location.origin + window.location.pathname,
      title: window.location.origin + window.location.pathname,
      path: window.location.pathname,
      search: location.search,
      url: location.href,
      referrer: document.referrer,
      prospect: window.storage.get('about_utm'),
      prospect_v2: about_utm,
    })
  } else {
    setTimeout(sendPageDatalayer, 1000)
  }
}

sendPageDatalayer()
