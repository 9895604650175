const isUndefined = (val) => val === undefined

export const state = () => ({
  settings: {
    theme: '',
    navigation_type: '', // default, partner_navigation
    removeHeader: false,
    removeFooter: false,
  },
})

export const mutations = {
  UPDATE(state, payload) {
    // page settings came from PageSettings slice in prismic
    payload = payload || {}
    payload = payload.primary ? payload.primary : payload

    state.settings.theme = !isUndefined(payload.theme) ? payload.theme : ''

    if (!isUndefined(payload.removeHeader)) state.settings.removeHeader = payload.removeHeader
    if (!isUndefined(payload.removeFooter)) state.settings.removeFooter = payload.removeFooter
    state.settings.navigation_type = payload.navigation_type
  },
}
