<template>
  <div id="mainRoot" data-luko-tracking="App-website">
    <transition name="infoBox">
      <InfoBox v-if="notification.text" :type="notification.type" class="fixed top-0 z-50" is-dismissible is-small @dismiss="onDismiss">
        {{ notification.text }}
      </InfoBox>
    </transition>

    <main>
      <nuxt />
    </main>
  </div>
</template>

<script>
import speedkitHydrate from 'nuxt-speedkit/hydrate'

export default {
  name: 'DefaultLayout',

  head() {
    const i18nSeo = this.$nuxtI18nSeo()
    const canonical = {
      rel: 'canonical',
      href: `${process.env.URL}${this.$route.path}/`.replace(/\/\/$/, '/'),
    }

    return {
      htmlAttrs: {
        ...i18nSeo.htmlAttrs,
      },
      meta: [...i18nSeo.meta],
      link: [canonical],
    }
  },

  components: {
    InfoBox: speedkitHydrate(() => import('@/components/InfoBox')),
  },

  computed: {
    notification() {
      return this.$store.state.notification
    },
  },
  methods: {
    onDismiss() {
      return this.$store.commit('notification/SET_NOTIFICATION', { text: '' })
    },
  },
}
</script>
