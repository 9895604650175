<template>
  <div data-luko-tracking="Header">
    <header class="relative z-20 w-full">
      <client-only>
        <PromoBanner v-if="bannerText" :banner-text="bannerText" />
      </client-only>
      <component :is="headerComponent" :data="headerData" :has-bugger-menu="hasBuggerMenu" />
    </header>

    <!-- fixed header (showup after passing hero section) -->
    <Transition name="slide">
      <header v-if="$store.state.header.fixedHeader" class="fixed top-0 z-20 w-screen">
        <client-only>
          <PromoBanner v-if="bannerText" :banner-text="bannerText" />
        </client-only>
        <FixedHeader :data="headerData" :has-bugger-menu="hasBuggerMenu" />
      </header>
    </Transition>
  </div>
</template>

<script>
import speedkitHydrate from 'nuxt-speedkit/hydrate'

import Header2021Q4Dark from './Header2021Q4Dark'
import Header2021Q4Pink from './Header2021Q4Pink'
import FixedHeader from './FixedHeader'

export default {
  components: {
    Header2021Q4Pink,
    Header2021Q4Dark,
    FixedHeader,
    PromoBanner: speedkitHydrate(() => import('./components/PromoBanner')),
  },

  computed: {
    headerData() {
      if (this.$store.state.page.settings.navigation_type === 'partner_navigation') {
        return this.$store.state.header.partnerHeaderData[this.$i18n.localeProperties.prismicIso.toLowerCase()]
      }
      return this.$store.state.header.headerData[this.$i18n.localeProperties.prismicIso.toLowerCase()]
    },
    bannerText() {
      const couponBannerData = this.$store.state.promoBanner?.bannerData
      if (couponBannerData.content?.[this.$i18n.locale]) {
        return couponBannerData.content?.[this.$i18n.locale]
      }

      if (couponBannerData.code === 'PLACEHOLDERMAIL') {
        return this.$i18n.t('CouponBanner.referral')
      }
      if (couponBannerData.type === 'month_off') {
        return this.$i18n.tc('CouponBanner.month', couponBannerData.amount, { value: couponBannerData.amount, coupon: couponBannerData.promo_code })
      }
      if (couponBannerData.type === 'percentage_off') {
        return this.$i18n.t('CouponBanner.percentage', {
          value: couponBannerData.amount,
          coupon: couponBannerData.promo_code,
        })
      }
      if (couponBannerData.type === 'amount_off') {
        return this.$i18n.t('CouponBanner.amount', {
          value: couponBannerData.amount / 100,
          coupon: couponBannerData.promo_code,
        })
      }
      return ''
    },
    hasPromoBanner() {
      return this.$store.getters['promoBanner/hasPromoBanner'] && this.bannerText
    },
    headerComponent() {
      let component = 'Header2021Q4Pink'
      if (['2021Q4-dark', 'dark', '2021Q4'].includes(this.$store.state.page.settings.theme)) {
        component = 'Header2021Q4Dark'
      }

      return component
    },
    hasBuggerMenu() {
      const menus = this.headerData.body.filter((s) => s.slice_type === 'secondary_menu_list')
      return !!menus.length || !!this.headerData.desktop_links.length
    },
  },
}
</script>

<style lang="scss" scoped>
.slide-enter-active {
  transform: translateY(-60px);

  transition: all 0.3s ease-in-out;
}
.slide-enter-to {
  transform: translateY(0);
  opacity: 1;
}

.slide-leave-active,
.slide-leave-to {
  display: none;
}
</style>
