import { Plugin, Braze } from '@demain-es/lukosiris'

export default ({ store, query, route, app }) => {
  new Plugin({
    projectId: 'CMS Front',
    loadEventData: () => {
      return {
        page: {
          related_product: app.store.state.tracking.related_product,
        },
      }
    },
    loadUserData: () => {},
    amplitudeInstance: process.env.IS_DEV === 'true' ? 'lukosiris-dev' : 'lukosiris',
    amplitudeCoverKey: process.env.AMPLITUDE_COVER_KEY,
    amplitudeKey: process.env.AMPLITUDE_KEY,
  }).initialize()

  /*
   ** LukoSiris Braze
   */
  const isAdviceFrMortgage = () => {
    return (
      process.env.CURRENT_DOMAIN === 'fr' &&
      store.state.navCta.onboarding_type === 'FR_MORTGAGE' &&
      ['/conseils/guide/', '/advice/guide/'].some((p) => window.location.pathname.includes(p))
    )
  }
  const isAdviceDe = () => {
    return process.env.CURRENT_DOMAIN === 'de' && ['/service/tipps/', '/advice/guide/'].some((p) => window.location.pathname.includes(p))
  }

  const handlePushNotification = (_braze) => {
    if (_braze.isPushSupported() === false || _braze.isPushPermissionGranted() || _braze.isPushBlocked()) return

    setTimeout(() => {
      _braze.requestPushPermission(
        function () {
          // Set custom attributes
          _braze.getUser().setCustomUserAttribute('web_push_consent_category', store.state.navCta.onboarding_type)
          _braze.requestImmediateDataFlush()
        },
        function () {
          // user declined
        }
      )
    }, 15000)
  }
  const handleEmailOptin = (_braze) => {
    const queryParams = window.location.search.replace('?', '').split('&')
    const q = queryParams.find(function (q) {
      return q.includes('u=')
    })
    const id = q ? q.replace('u=', '') : null
    // remove query params
    window.history.replaceState({}, document.title, window.location.pathname)

    if (!id) {
      window.LukoTracking.trackEvent({
        id: 'Email Optin Failed',
        properties: {
          error: { message: "Email Optin: 'u' query param doesn't exist" },
        },
      })
      return
    }

    _braze.getUser().getUserId(function (originalUserId) {
      _braze.changeUser(id)
      _braze.getUser().addToSubscriptionGroup('d5159dcc-77cf-449e-990e-e5f86c79f244')
      _braze.getUser().addToSubscriptionGroup('619a6cc5-048b-4117-8120-cb58715133f7')
      const result = _braze.getUser().setEmailNotificationSubscriptionType('opted_in')
      if (originalUserId !== id) {
        _braze.changeUser(originalUserId)
      }

      if (result) {
        _braze.logCustomEvent('Email Optin Success')
        _braze.requestImmediateDataFlush(function () {
          window.LukoTracking.trackEvent({
            id: 'Email Optin Success',
            properties: { optin: { brazeId: id } },
          })
        })
      } else {
        window.LukoTracking.trackEvent({
          id: 'Email Optin Failed',
          properties: {
            error: { message: 'Email Optin: setEmailNotificationSubscriptionType failed' },
            optin: {
              brazeId: id,
            },
          },
        })
      }
    })
  }

  const handleWaitlistProduct = (_braze, eventData) => {
    _braze.getUser().setEmail(eventData.subscriber.email)
    _braze.logCustomEvent(eventData.brazeCustomEvent)
    _braze.requestImmediateDataFlush()
  }

  const handleNewsletter = (_braze, eventName, eventData) => {
    const subscriber = eventData.subscriber
    _braze.getUser().setEmail(subscriber.email)
    _braze.getUser().setCustomUserAttribute('Page CategoryName', subscriber.category)
    _braze.getUser().setCustomUserAttribute('Page Uid', subscriber.uid)
    _braze.getUser().setCustomUserAttribute('Page Language', subscriber.lang)
    _braze.getUser().setCustomUserAttribute('Page Country', subscriber.country)
    _braze.logCustomEvent(eventName)
    _braze.requestImmediateDataFlush()

    window.LukoTracking.trackEvent({
      id: 'Newsletter Subscription Success',
      properties: {
        newsletter: {
          email: subscriber.email,
          category: subscriber.category,
          uid: subscriber.uid,
          lang: subscriber.lang,
          country: subscriber.country,
        },
      },
    })
  }

  const handleLaunchPageContactGiven = (_braze, eventName, eventData) => {
    const TRANSACTIONAL_SMS_GROUP = '619a6cc5-048b-4117-8120-cb58715133f7'
    const MARKETING_SMS_GROUP = 'd5159dcc-77cf-449e-990e-e5f86c79f244'

    const subscriber = eventData.subscriber

    _braze.getUser().setPhoneNumber(subscriber.phone)
    _braze.getUser().setCustomUserAttribute('Launch Page Uid', subscriber.uid)
    _braze.getUser().setCustomUserAttribute('Page Language', subscriber.lang)
    _braze.getUser().setCustomUserAttribute('Page Country', subscriber.country)
    _braze.getUser().addToSubscriptionGroup(MARKETING_SMS_GROUP)
    _braze.getUser().addToSubscriptionGroup(TRANSACTIONAL_SMS_GROUP)
    _braze.logCustomEvent(eventName)
    _braze.requestImmediateDataFlush()
  }

  const processBrazeEvent = (_braze, eventName, eventData) => {
    if (eventName === 'Email Optin') return handleEmailOptin(_braze)
    if (eventName === 'Cookies Accepted') return handlePushNotification(_braze)
    if (eventName === 'Waitlist Product') return handleWaitlistProduct(_braze, eventData)
    if (eventName === 'Newsletter Request') return handleNewsletter(_braze, eventName, eventData)
    if (eventName === 'Launch Page Contact Given') return handleLaunchPageContactGiven(_braze, eventName, eventData)
  }

  const LukosirisBraze = new Braze({
    key: process.env.IS_DEV === 'true' ? 'bce335db-f7a2-4a15-b6a1-fe90cf60fe4b' : '564e39c0-febd-414b-932b-f99bfa0d38fa',
    enableLogging: process.env.IS_DEV === 'true',
    eventsList: ['Email Optin', 'Waitlist Product', 'Newsletter Request', 'Launch Page Contact Given'],
    processEvent: processBrazeEvent,
    baseUrl: 'brz-api.luko.eu',
  })

  // Avoid braze loading for all pages by Cookies Accepted
  if (isAdviceFrMortgage() || isAdviceDe()) {
    LukosirisBraze.eventsList.push('Cookies Accepted')
  }
  LukosirisBraze.init()
}
