export default {
  actions: {
    async nuxtServerInit({ dispatch, commit }, { app }) {
      const lang = app.i18n.localeProperties.prismicIso
      await Promise.all(['setAdviceCategoryData'].map((action) => dispatch(action, lang)))
      if (process.env.HAS_WAITLIST === 'true') commit('SET_HAS_WAITLIST', true)
      if (!process.env.CURRENT_DOMAIN) throw new Error('CURRENT_DOMAIN env missing')
      commit('SET_URL', process.env.URL)
    },

    async setAdviceCategoryData({ commit }, lang) {
      try {
        const { results: categoriesPages } = await this.$prismic.api.query(this.$prismic.predicates.at('document.type', 'advices_category_page'), {
          lang,
        })
        const allCategoryData = categoriesPages.reduce((acc, category) => {
          acc[category.id] = {
            uid: category.uid,
            category_name: category.data.category_name,
          }
          return acc
        }, {})
        commit('SET_ADVICE_CATEGORY_DATA', allCategoryData)
      } catch (error) {
        console.error('Fail to fetch advice categories', error)
        throw error
      }
    },

    setAlternateLanguages({ commit }, list) {
      commit('SET_ALTERNATE_LANGUAGES', list)
    },
  },

  state() {
    return {
      alternateLanguages: [],
      translationUid: null,
      defaultUid: null,
      hasWaitlist: false,
      url: '',
      navCta: { url: '', text: '' },
      categoryUid: null,
    }
  },

  mutations: {
    SET_ADVICE_CATEGORY_DATA(state, data) {
      state.adviceCategoryData = data
    },

    SET_ALTERNATE_LANGUAGES(state, list) {
      state.alternateLanguages = list
    },

    SET_HAS_WAITLIST(state, bool) {
      state.hasWaitlist = bool
    },

    SET_URL(state, url) {
      state.url = url
    },

    SET_NAV_CTA(state, { url, text, onboarding_type }) {
      state.navCta = { url, text, link_type: 'Web', onboarding_type }
    },

    SET_PAGE_CATEGORY(state, categoryUid) {
      state.categoryUid = categoryUid
    },
  },
}
