<template>
  <button class="py-[8px] pr-[12px]" :class="$style.menu" data-luko-tracking="BuggerMenu" v-on="$listeners">
    <MenuIcon :class="{ 'path-black': theme === 'light', 'path-white': theme !== 'light' }" role="img" />
  </button>
</template>
<script>
import MenuIcon from 'LkIcons/Menu.vue'

export default {
  components: {
    MenuIcon,
  },
  props: {
    theme: {
      type: String,
      default: 'light',
    },
  },
}
</script>
<style lang="scss" module>
.menu {
  @media (min-width: 1024px) {
    display: none;
  }
}
</style>
