<template>
  <img
    v-if="imageObject && imageObject.url"
    :src="image.src"
    :alt="imageAlt"
    :sizes="image.sizes"
    :srcset="image.srcset"
    :loading="lazy ? 'lazy' : undefined"
    data-luko-tracking="PImage"
  />
</template>

<script>
export default {
  name: 'PImage',
  head() {
    if (!this.preload || !this.imageObject) return {}

    return {
      link: [
        {
          rel: 'preload',
          href: this.image.src,
          as: 'image',
          imagesrcset: this.image.srcset,
          imagesizes: this.image.sizes,
        },
      ],
    }
  },
  props: {
    imageObject: {
      type: Object,
      default: () => null,
    },
    sizes: {
      type: String,
      default: () => '', // "xs:100vw sm:100vw md:50vw lg:400px"
    },
    quality: {
      type: Number,
      default: () => 80,
    },
    fit: {
      type: String,
      default: () => 'cover', // cover, contain, fill, inside, outside
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    preload: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    image() {
      if (!this.imageObject?.url) return ''
      if (!this.sizes) {
        return {
          src: this.normalize(this.imageObject.url),
        }
      }

      const img = this.$img.getSizes(this.imageObject.url, {
        modifiers: {
          quality: this.quality,
          fit: this.fit,
        },
        sizes: this.sizes,
      })
      return {
        src: this.normalize(img.src),
        sizes: this.normalize(img.sizes),
        srcset: this.normalize(img.srcset),
      }
    },
    imageAlt() {
      return this.imageObject?.alt
    },
  },
  methods: {
    normalize(text) {
      return text.replace(/rect=.*?&|h=.*?&/g, '')
    },
  },
}
</script>
