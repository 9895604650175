import axios from 'axios'

const PartnerAPI = axios.create({
  baseURL: process.env.PARTNER_API,
  withCredentials: true,
})

PartnerAPI.interceptors.request.use(function (config) {
  config.headers = { Authorization: `Bearer ${process.env.PARTNER_KEY}`, 'Content-Type': 'application/json', ...config.headers }

  return config
})

export default {
  // controllerQuoteCall: new AbortController(),

  async createQuote(prospectData) {
    // this.controllerQuoteCall = new AbortController()
    // const signal = this.controllerQuoteCall.signal
    try {
      const { data } = await PartnerAPI.post(`/v1/prospect/price?lang=fr`, prospectData)

      return data
    } catch (e) {
      if (axios.isCancel(e)) return null

      return Promise.reject(e)
    }
  },

  abortQuote() {
    // if (this.controllerQuoteCall) {
    //   this.controllerQuoteCall.abort()
    // }
  },
}
