<template>
  <div>
    <HeaderModule />
    <ErrorModule data-luko-tracking="Page-Error" />
    <FooterModule />
  </div>
</template>

<script>
import FooterModule from '@/modules/Footer'
import HeaderModule from '@/modules/Header'
import ErrorModule from '@/modules/ErrorModule'

export default {
  layout: 'empty',

  components: {
    HeaderModule,
    FooterModule,
    ErrorModule,
  },

  fetch() {
    this.$store.commit('page/UPDATE', { theme: '2021Q4-pink' })
  },
}
</script>
