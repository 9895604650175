<template>
  <PLink class="relative left-0 xlg:left-[30px] sm:left-[12px]" :link-object="logoLinkObject">
    <LukoLogoBlue role="img" :class="{ ['path-white']: theme !== 'light' }" />
    <span :class="$style.hidden">Luko logo</span>
  </PLink>
</template>
<script>
import LukoLogoBlue from '@/assets/icons/luko-logo-blue.svg?inline'

export default {
  components: {
    LukoLogoBlue,
  },
  props: {
    theme: {
      type: String,
      default: 'light',
    },
  },
  data() {
    return {}
  },
  computed: {
    logoLinkObject() {
      return {
        link_type: 'Document',
        type: 'home_page',
        lang: this.$i18n.localeProperties.prismicIso.toLowerCase(),
      }
    },
  },
}
</script>
<style lang="scss" module>
.hidden {
  position: absolute !important;

  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  border: 0;
  overflow: hidden;

  white-space: nowrap; /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */

  clip: rect(1px 1px 1px 1px); /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
  clip: rect(1px, 1px, 1px, 1px); /*maybe deprecated but we need to support legacy browsers */
  clip-path: inset(50%); /*modern browsers, clip-path works inwards from each corner*/
}
</style>
