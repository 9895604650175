export const headerMixins = {
  data() {
    return {
      isMobileMenuOpen: false,
    }
  },
  watch: {
    isMobileMenuOpen(isOpen) {
      document.body.style.overflow = isOpen ? 'hidden' : 'unset'
      if (document.getElementById('promo-banner')?.style.zIndex) {
        document.getElementById('promo-banner').style.zIndex = isOpen ? '0' : '9999'
      }
    },
  },
}
