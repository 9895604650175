import { ONBOARDING_TYPE, UNSUPPORTED_FRENCH_DEPARTMENTS } from '@/utils/constants'

const countries = {
  FR: 'France',
  ES: 'Spain',
  DE: 'Germany',
  GB: 'United Kingdom',
  IT: 'Italy',
}

const getLinkWithQueryParams = (link, queryParams) => {
  return encodeURI([link, queryParams.join('&')].filter((e) => e).join('?'))
}

export const getOnboardingLink = {
  methods: {
    getOnboardingLink(address, option = { queryParams: [], type: 'home', url: null }) {
      const lang = this.$i18n.locale

      if (lang === 'es')
        return 'https://app.tuio.com/chat/estimate/2?agencyId=vVLtSYYBtLKf-5v5Omhf&agentId=vlLtSYYBtLKf-5v5PGiN&utm_source=luko&utm_campaign=luko_onboarding'

      const onboard = process.env.IS_DEV === 'true' ? `https://devnoscope.luko.eu/${lang}/onboard` : `https://www.luko.eu/${lang}/onboard`

      const onboardingUrls = {
        [ONBOARDING_TYPE.home]: onboard,
        [ONBOARDING_TYPE.mri]: 'https://mri.luko.eu',
        [ONBOARDING_TYPE.mortgage]: onboard,
        [ONBOARDING_TYPE.pet]: `${onboard}/pets/`,
        [ONBOARDING_TYPE.escooter]: `${onboard}/escooter/`,
        [ONBOARDING_TYPE.gli]: onboard,
      }
      const url = onboardingUrls[option.type || 'home']
      const queryParams = option.queryParams?.filter(([k, v]) => !!v).map(([k, v]) => `${k}=${v}`) || []

      if (!address) return getLinkWithQueryParams(option.url || url, queryParams)
      // Don't send address query param if FR uncovered departement
      if (
        address.country_code?.toUpperCase() === 'FR' &&
        option.type === 'mri' &&
        UNSUPPORTED_FRENCH_DEPARTMENTS.find((code) => address.postcode?.startsWith(code))
      ) {
        return getLinkWithQueryParams(option.url || url, queryParams)
      }

      // Send address query params + additional query param if exist
      address.country = countries[address.country_code]
      const go_fast = address.country_code?.toUpperCase() !== 'DE'

      if (!queryParams.find((qp) => qp === 'bypass=true')) {
        queryParams.push(`go_fast=${go_fast}`)
      }
      const queryParamWithAddress = [...queryParams, ...Object.entries(address).map(([k, v]) => `$.home.main_asset.${k}=${v}`)]

      return getLinkWithQueryParams(option.url || url, queryParamWithAddress)
    },
  },
}
