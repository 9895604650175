<template>
  <div>
    <PImage :image-object="data.footer_luko_logo" lazy class="mb-[8px] block h-[64px] w-[124px]" />
    <PLink :link-object="data.footer_luko_email_link" class="mb-[8px] block text-white no-underline opacity-50 hover:underline">
      {{ data.footer_luko_email_text }}
    </PLink>
    <PLink
      v-if="data.footer_luko_address_link && data.footer_luko_address_text"
      :link-object="data.footer_luko_address_link"
      class="block text-white no-underline opacity-50 hover:underline"
    >
      {{ data.footer_luko_address_text }}
    </PLink>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>
