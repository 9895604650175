<template>
  <component
    :is="component"
    :ref="rel"
    :data-luko-tracking="trackingId"
    :class="[
      { [$style.root]: true },
      { [$style['root--light']]: isLight },
      { [$style['root--small']]: isSmall },
      { [$style['root--danger']]: isDanger },
      { [$style['root--success']]: isSuccess },
      { [$style['root--untouched']]: isUntouched },
      { [$style['root--secondary']]: isSecondary },
      { [$style['root--white']]: isWhite },
      { [$style['root--warning']]: isWarning },
      { [$style['root--is-loading']]: loading },
      { [$style['root--outlined']]: isOutlined },
    ]"
    :href="computedHref"
    :disabled="disabled"
    :target="computedTarget"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span
      v-if="$slots.icon"
      :class="{
        '!path-white': pathColor === 'white',
        '!path-bluko-500': pathColor === 'bluko',
        '!path-black': pathColor === 'black',
        '!path-gray-300': pathColor === 'gray',
        [$style.icon]: true,
        [$style['icon-right']]: hasIconRight,
      }"
    >
      <slot name="icon" />
    </span>

    <span :class="$style.slot">
      <slot />
    </span>
    <svg v-if="loading" width="0" height="0">
      <clipPath id="LK_button-clip">
        <path
          d="M14 4C8.47715 4 4 8.47715 4 14C4 19.5228 8.47715 24 14 24C19.5228 24 24 19.5228 24 14C24 12.8954 24.8954 12 26 12C27.1046 12 28 12.8954 28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C15.1046 0 16 0.89543 16 2C16 3.10457 15.1046 4 14 4Z"
          fill="#000"
        />
      </clipPath>
    </svg>
  </component>
</template>

<script>
import { useUrl } from '@/composables/useUrl'
import { getFormattedLinkObject } from '@/mixins/getFormattedLinkObject'

const { urlToLinkObject } = useUrl()
export default {
  name: 'LkButton',
  mixins: [getFormattedLinkObject],
  props: {
    simpleUrl: {
      type: String,
      default: null,
    },
    linkObject: {
      type: Object,
      default: null,
    },
    targetedSection: {
      type: String,
      default: null, // #section-id
    },
    rel: {
      type: String,
      default: undefined,
    },
    target: {
      type: String,
      default: undefined,
    },
    isLight: {
      type: Boolean,
      default: false,
    },
    isSecondary: {
      type: Boolean,
      default: false,
    },
    isDanger: {
      type: Boolean,
      default: false,
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
    isUntouched: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: false,
    },
    isWarning: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasIconRight: {
      type: Boolean,
      default: false,
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
    dataLukoTracking: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    component() {
      if (this.computedHref) {
        return 'a'
      } else {
        return 'button'
      }
    },
    computedHref() {
      if (this.linkObject) return this.$prismic.asLink(this.getFormattedLinkObject(this.linkObject, this.targetedSection))
      if (this.simpleUrl) return this.$prismic.asLink(this.getFormattedLinkObject(urlToLinkObject(this.simpleUrl), this.targetedSection))
      if (this.targetedSection)
        return this.$prismic.asLink(this.getFormattedLinkObject(urlToLinkObject(process.env.URL + this.$route.fullPath), this.targetedSection))
      return undefined
    },
    trackingId() {
      const ids = ['Button']
      if (this.dataLukoTracking) {
        ids.push(this.dataLukoTracking)
      }
      if (this.computedHref?.includes('/onboard')) {
        ids.push('Onboarding')
      }
      return ids.join('-') || undefined
    },
    computedTarget() {
      if (this.linkObject?.target) return this.linkObject.target
      return this.target || '_self'
    },
    pathColor() {
      if (this.disabled) return 'gray'
      if (this.isSecondary) return 'black'
      if (this.isOutlined && this.isWhite) return 'white'
      if (this.isOutlined || this.isLight) return 'bluko'
      if (this.isWhite) return 'bluko'
      return 'white'
    },
  },
}
</script>

<style lang="scss" module>
.root {
  @include typo-body-bold;
  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;

  color: white;
  text-align: center;
  text-decoration: none;

  background-color: $bluko-500;
  outline: none;
  cursor: pointer;

  transition: background-color 200ms ease-in-out;

  &:disabled.root {
    color: $gray-300;

    background-color: $gray-50;
    cursor: not-allowed;
  }

  &:disabled.root--secondary {
    background-color: $gray-100;
  }

  &:focus,
  &:hover {
    background-color: $bluko-400;
  }
}

.root.root--is-loading {
  cursor: progress;

  .icon,
  .slot {
    opacity: 0;
  }

  &::before {
    position: absolute;

    width: 28px;
    height: 28px;
    border-radius: 50%;

    background: white;
    background: conic-gradient(white 5%, transparent 0% 30%, white 90%);

    animation: loader_animation 1s infinite linear;

    content: '';
    clip-path: url(#LK_button-clip);
  }
}

.root.root--light {
  color: $bluko-500;

  background-color: $bluko-100;

  &:hover,
  &:focus {
    background-color: $bluko-75;
  }
}

.root.root--light.root--is-loading {
  &::before {
    background: conic-gradient($bluko-300 5%, transparent 0% 30%, $bluko-400 90%);
    background-color: transparent;

    content: '';
  }
}

.root.root--danger {
  color: white;

  background-color: $terracota-500;

  &:hover,
  &:focus {
    background-color: $terracota-400;
  }
}

.root.root--light.root--danger {
  color: $terracota-500;

  background-color: $terracota-100;

  &:hover,
  &:focus {
    background-color: $terracota-75;
  }
}

.root.root--light.root--danger.root--is-loading {
  &::before {
    background: conic-gradient($terracota-500 5%, transparent 0% 30%, $terracota-500 90%);
    background-color: $terracota-500;

    content: '';
  }
}

.root.root--success {
  color: white;

  background-color: $mint-500;

  &:hover,
  &:focus {
    background-color: $mint-400;
  }
}

.root.root--untouched {
  color: white;

  background-color: $gray-500;

  &:hover,
  &:focus {
    background-color: $gray-400;
  }
}

.root.root--warning {
  color: white;

  background-color: $orange-500;

  &:focus,
  &:hover {
    background-color: $orange-400;
  }
}

.root.root--small {
  padding: 8px 16px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  .icon svg {
    width: 12px;
    height: 12px;
  }
}

.root.root--white {
  color: $bluko-500;

  background-color: white;

  &:focus,
  &:hover {
    background-color: rgba(white, 0.8);
  }
}

.root.root--secondary {
  color: $gray-1000;

  background-color: $gray-100;

  &:focus,
  &:hover {
    background-color: $gray-75;
  }
}

.root.root--outlined {
  border: 2px solid $bluko-500;

  color: $bluko-500;

  background-color: transparent;

  &:focus,
  &:hover {
    background-color: $bluko-100;
  }
  &.root--white {
    border: 2px solid white;

    color: white;

    &:focus,
    &:hover {
      background-color: rgba(white, 0.3);
    }
  }
}

.icon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 10px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.icon.icon-right {
  order: 10;
  margin-right: 0;
  margin-left: 10px;
}

@keyframes loader_animation {
  to {
    transform: rotate(360deg);
  }
}
</style>
