import { useUrl } from '@/composables/useUrl'

const { forwardUrl } = useUrl()

export const getFormattedLinkObject = {
  methods: {
    getFormattedLinkObject(linkObject, targetedSection) {
      const url = new URL(process.env.URL + this.$route.fullPath)

      if (targetedSection) {
        url.hash = `${targetedSection}`.replace('#', '')

        return {
          type: 'Web',
          url: url.toString().replace('#', '/#').replace('//#', '/#'),
        }
      }
      if (!linkObject) return null

      const newLinkObject = { ...linkObject }

      if (linkObject.url?.startsWith('https://calendly.com/')) {
        url.hash = '#calendly'
        return {
          ...newLinkObject,
          url: url.toString().replace('#', '/#').replace('//#', '/#'),
        }
      }

      // This is for target section.
      // Because sometimes content team put # directly in url :(
      if (linkObject.url?.match(/#.*$/) && linkObject.url?.includes('luko.eu') && !linkObject.url?.includes('onboard')) {
        url.hash = linkObject.url.match(/#.*$/)[0]

        return {
          ...newLinkObject,
          url: url.toString().replace('#', '/#').replace('//#', '/#'),
        }
      }

      if (linkObject.url?.includes('/onboard') || linkObject.url?.includes('/my-account')) {
        return {
          ...newLinkObject,
          url: forwardUrl(process.env.IS_DEV === 'true' ? linkObject.url.replace('www.luko.eu', 'devnoscope.luko.eu') : linkObject.url),
        }
      }
      if (linkObject.url?.includes('companion.luko.eu')) {
        return {
          ...newLinkObject,
          url: process.env.IS_DEV === 'true' ? linkObject.url.replace('companion.luko.eu', 'companion-dev.luko.eu') : linkObject.url,
        }
      }

      if (linkObject.url) {
        return {
          ...newLinkObject,
          url: forwardUrl(linkObject.url),
        }
      }

      if (['Document', 'Media'].includes(linkObject.link_type)) {
        return {
          ...newLinkObject,
        }
      }

      return {
        ...newLinkObject,
        url: '/not-found/',
      }
    },
  },
}
