export const state = () => ({
  bannerData: {
    amount: null,
    content: null,
    type: null,
    promo_code: null,
    code: null,
  },
})

export const mutations = {
  SET_TOP_BANNER_DATA(state, data) {
    state.bannerData.amount = data.amount
    state.bannerData.content = data.content
    state.bannerData.type = data.type
    state.bannerData.promo_code = data.promo_code
    state.bannerData.code = data.code
  },
}

export const getters = {
  hasPromoBanner(state) {
    return !!state.bannerData.promo_code
  },
}
